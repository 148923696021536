import Layout from "../components/layout"
import SEO from "../components/seo"
import React from "react"
import TextPageComponent from "../components/shared/text-page"
import { Link } from "gatsby"

const PolicyPage = () => (
  <Layout forceFixedHeader={true} page={"policy"}>
    <SEO title="Документация" noindex="noindex" />
    <TextPageComponent title={"Документация"}>
      <p>
        <b>Информация о совершенствовании ПО Система Параплан</b><br />
        Ссылка на документ: <a href="/files/software-system-paraglider.pdf" target="_blank">Информация о совершенствовании ПО Система Параплан.pdf</a>
      </p>

      <p>
        <b>Скан справки от регистратора доменных имен.</b><br />
        Ссылка на документ: <a href="/files/domain_owner_cert_print.pdf" target="_blank">Скан справки ООО ХБР от регистратора доменных имен.pdf</a>
      </p>

      <p>
        <b>Описание функциональных характеристик Системы “Параплан”.</b> <br />
        Ссылка на документ: <a href="/files/func-char-po-paraplan.docx" target="_blank">Функциональные характеристики программного обеспечения «Параплан».docx</a>
      </p>

      <p>
        <b>Уведомление, что Система “Параплан” распространяется в виде интернет-сервиса, специальные действия по установке ПО на стороне пользователя не требуются.</b> <br />
        Ссылка на документ: <a href="/files/internet-setvice-paraplan.pdf" target="_blank">УВЕДОМЛЕНИЕ о распространении ПО «Параплан» в виде Интернет-сервиса.pdf</a>
      </p>

      <p>
        <b>Руководство пользователя Системы “Параплан”.</b> <br />
        Ссылка на документ:<a href="/files/user-manual-2.pdf" target="_blank">Руководство пользователя (2).pdf</a>
      </p>

      <p>
        <b>Информация о стоимости программного обеспечения Системы “Параплан”.</b> <br />
        Ссылка на документ: <a href="/files/price-info.pdf" target="_blank">Сведения о стоимости ПО.pdf</a>
      </p>

      <p>
        <b>Описание ЖЦ Системы “Параплан”.</b> <br />
        Ссылка на документ: <a href="/files/paragliding-software-lifecycle.docx" target="_blank">Жизненный цикл ПО Параплан (1).docx</a>
      </p>

      <p>
        <b>Общие положение эксплуатации и технической по поддержки Системы “Параплан”.</b> <br />
        Ссылка на документ: <a href="/files/general-provisions.pdf" target="_blank">Общие положения эксплуатации и технической поддержки системы «Параплан».pdf</a>
      </p>

      <p>
        <b>Приказ об установлении цен на ПО “Параплан”.</b> <br />
        Ссылка на документ: <a href="/files/order_on_ setting_paraplan_prices.pdf" target="_blank">Приказ об установлении цен на ПО «Параплан».pdf</a>
      </p>

      <p>
        <b>Договор-оферта о предоставлении консультации по работе с сервисом «Параплан».</b> <br />
        Ссылка на документ: <a href="/files/agreement-consult.pdf" target="_blank">Договор-оферта о предоставлении консультации по работе с сервисом «Параплан».pdf</a>
      </p>

      <p>
        <b>Чек-лист для начала работы в Параплан CRM.</b> <br />
        Ссылка на документ: <a href="/files/setup_checklist.pdf" target="_blank">Чек-лист по быстрой настройке системы.pdf</a>
      </p>

      <p>
        <b>Чек-лист по увеличению прибыли детского центра.</b> <br />
        Ссылка на документ: <a href="/files/checklist-profit.pdf" target="_blank">15 ШАГОВ по увеличению прибыли детского центра.pdf</a>
      </p>
    </TextPageComponent>
  </Layout>
)

export default PolicyPage
